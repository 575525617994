<template>
  <el-container>
    <el-header>
      <div><img src="../../public/finish.png" alt="" /></div>
      <div>完成</div>
      <br />
      <div>发货管理图片上传完成，您可以关闭页面</div>
      <br />

    </el-header>
    <el-main
      ><el-button type="primary" @click="close">关闭页面</el-button></el-main
    >
  </el-container>
</template>

<script>
export default {
  methods: {
    close() {
    //   window.opener = null;
      this.$router.push("/");

      // window.open("about:blank", "_top").close()
    },
  },
};
</script>

<style lang="less" scoped>
.el-header {
  height: 30vh !important;
  text-align: center;
  img {
    width: 100px;
    // margin-top: 15px;
    // font-size: 40px;
  }
}
.el-main {
  text-align: center;
  .el-button {
    width: 100%;
    font-size: 20px;
  }
}
</style>